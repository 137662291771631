import ReactDOM from "react-dom";
import "./index.css";
import "./css/main.scss";
// import * as serviceWorker from "./serviceWorker";
import Platform from "./Main/Platform";
import MainHIS from "./HIS/MainHIS";
import MainPatient from "./patient-lib/MainPatient";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import CONFIG from "config/config";
import { Loader } from 'semantic-ui-react'
import ModalNetWorkError from "react-lib/apps/common/ModalNetWorkError";

let finishLoadConfig = false
fetch("/static/base.json").then((response) => {
  if (response.ok) {
      response.json().then(json => {
      if (Object.keys(json).length > 0) {
        CONFIG.recreate(json);
      }
    })
  } 
  finishLoadConfig = true
  renderContent();
});


// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
// import { CaptureConsole } from '@sentry/integrations';

// Raven.config('http://71dd760fb02a4f9f8154669d94baecc3@sentry.drjaysayhi.com/25').install()

// Sentry.init({
//     dsn: "https://71dd760fb02a4f9f8154669d94baecc3@sentry.drjaysayhi.com/25",
//     debug: true,
//     integrations: [new BrowserTracing()],
//     // integrations: [
// 	//     new CaptureConsole({
// 	//       levels: ['error']
// 	//     })
// 	//   ],

//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
//   });

const params = new URLSearchParams(window.location.search);
let app = params.get("app");

if (!app) {
  app = "HIS";
  const url = new URL(window.location.href);
  url.searchParams.set("app", "HIS");
  window.history.pushState(null, "", url.toString());
}

const renderContent = () => {
  ReactDOM.render(
    <BrowserRouter>
    <ModalNetWorkError/>
      {finishLoadConfig ? (app === "HIS" ? <MainHIS /> : app === "MOB" ? <MainPatient /> : <Platform />) : <Loader/>} 
    </BrowserRouter>,
    document.getElementById("root")
  );
}

renderContent();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// serviceWorker.register();
if (CONFIG.BYPASS_SERVICE_WORKER) {
  // console.log("BYPASS SERVICE WORKER 1 ");
  console.log("serviceWorkerRegistration.unregister");
  serviceWorkerRegistration.unregister();
  // console.log("BYPASS SERVICE WORKER 2");
} else {
  console.log("serviceWorkerRegistration.register");
  serviceWorkerRegistration.register();

  // navigator?.serviceWorker
  //   ?.getRegistrations()
  //   .then(function (registrations) {
  //     console.log(
  //       "remove previous service worker if have! registrations: ",
  //       registrations
  //     );
  //     for (let registration of registrations) {
  //       console.log("registration: ", registration);
  //       registration.unregister().then(() => {
  //         window.caches.keys().then((names) => {
  //           for (let name of names) {
  //             caches.delete(name);
  //           }
  //         });
  //       })
  //     }
  //   })
}
